<template>
  <div class="web-video-containter" :class="{'web-video-containter-full-width': isShowPreviewPhotos }">
    <div class="switch-camera" v-if="!isShowPreviewPhotos ">
      <div class="switch-camera-wrapper">
        <i class="bx bx-camera" @click="switchCamera"></i>
        <div class="switch-number"> {{(currentCameraMode === 'user') ? 2 : 1}}</div>
      </div>
    </div>
    <template v-if="isShowPreviewPhotos">
      <div class="preview-img-gallery">
        <div class="" v-for="(img, index) in photos" :key="index">
          <label class="form-check-inline checkbox preview-label">
            <input class="form-check-input"
                   type="checkbox"
                   :id="img.id"
                   v-model="selectedImages"
                   :value="img.id"
                   :data-id="img.id">
            <span  class="icon"><i class='bx bx-check'></i></span>
          </label>

          <i @click="removeImg(img.id)" class='bx bx-trash preview-img-remove'></i>

          <img :src="img.url" class="preview-img" @click="selectImg(img.id)" :class="{'active-preview-image': selectedImages.includes(img.id)}" />
        </div>
      </div>
    </template>
    <template v-else>
      <video autoplay muted playsinline id="preview" :class="{'user-mode': currentCameraMode !== 'environment'}"></video>
    </template>

    <div class="actions">
      <button type="button"
              @click="onClickDone"
              class="btn btn-primary waves-effect waves-light">
        Done
      </button>
      <button type="button" id="take-photo" :class="{'red-take-photo': isRecording}" class="btn-shot" @click="onTakePhotoClick" tabindex="1"></button>
      <div @click="onImageSectionClick" class="image-section" style="">Image Section</div>
    </div>
  </div>
</template>


<script>
import Axios from "axios";
import {mapGetters} from "vuex";
import _ from "lodash";
import SiteHeader from "@/components/common/site-header.vue";

export default {
  name: "webCamera",
  components: {SiteHeader},
  data() {
    return {
      isRecording: false,
      activeVideoStream: null,
      photos: [],
      selectedImages: [],
      currentCameraMode: 'environment',
      isShowPreviewPhotos: false,
    }
  },
  mounted() {
    this.startVideo()
  },
  computed: {
    ...mapGetters({
      getterAllEstimates: 'estimate/getAllEstimates',
    }),
    estimateId() {
      return _.maxBy(this.getterAllEstimates, (e) => {
        return Number(e.estimate_id)
      })?.estimate_id
    },
  },
  methods: {
    selectImg(id) {
      if (_.includes(this.selectedImages, id)) {
        this.selectedImages.splice(this.selectedImages.indexOf(id), 1)
      } else {
        this.selectedImages.push(id)
      }
    },
    removeImg(id) {
      if (_.includes(this.selectedImages, id)) {
        this.selectedImages.splice(this.selectedImages.indexOf(id), 1)
      }
      let index = _.findIndex(this.photos, (p) => {
        return Number(p.id) == Number(id)
      })
      this.photos.splice(index, 1)
    },
    onImageSectionClick() {
      this.isShowPreviewPhotos = !this.isShowPreviewPhotos
      if (!this.isShowPreviewPhotos) {
        this.startVideo()
      }
    },
    onClickDone() {
      if (_.isEmpty(this.photos)) {
        return
      }
      NProgress.start()
      let data = {}
      let uid = Date.now().toString() + Math.random().toString(36).substr(2, 4)
      data[uid] = {}
      data[uid].image_ids = this.selectedImages

      Axios({
        method: 'post',
        responseType: 'json',
        headers: { 'Autosave': true },
        url: `/fe/estimate/${this.estimateId}`,
        validateStatus: function (status) {
          return status < 500
        },
        data: data
      })
          .then((r) => {
            if (r.status === 200) {
              toastr.success("Photos successfully sent!");

              _.forEach(this.selectedImages, (id) => {
                let index = _.findIndex(this.photos, (p) => {
                  return Number(p.id) == Number(id)
                })
                if (index !== -1) {
                  this.photos.splice(index, 1)
                }
              })

              this.selectedImages.splice(0, this.selectedImages.length)
            }
          })
      .finally(() => {
        NProgress.done()
      })

    },
    switchCamera() {
      this.updateActiveCameraSelection()
    },
    async updateActiveCameraSelection() {
      try {
        this.currentCameraMode = (this.currentCameraMode === 'user') ? 'environment' : 'user';
        this.startVideo()
      } catch (error) {
        this.saveError(error)
      }
    },
    saveError(e) {
      Axios.post('/fe/logs/front', {error: String(e)}).then(r => {
      })
    },
    createThumbnail(video, scaleFactor) {
      if (scaleFactor == null) {
        scaleFactor = 1;
      }
      let w = video.videoWidth * scaleFactor;
      let h = video.videoHeight * scaleFactor;
      let canvas = document.createElement("canvas");
      canvas.style.margin = "5px";
      canvas.width = w;
      canvas.height = h;

      let ctx = canvas.getContext("2d");

      if (this.currentCameraMode !== 'environment') {
        ctx.translate(w, 0);
        ctx.scale(-1, 1);
      }
      ctx.drawImage(video, 0, 0, w, h);
      return canvas;
    },
    async onTakePhotoClick(e) {

      try {
        e.preventDefault();
        e.stopPropagation();

        this.isRecording = true

        if (window?.navigator?.vibrate) {
          window.navigator.vibrate([200])
        }

        let video = document.getElementById("preview");
        let canvas = this.createThumbnail(video, 1);

        await canvas.toBlob((blob) => {
          setTimeout(() => {
            this.isRecording = false;
            this.savePhoto(blob);
          }, 100)
        });
      } catch (error) {
        this.saveError(error)
      }
    },
    resizeFile(file, width = undefined, height = undefined, quality = 0.7) {
      return new Promise((resolve) => {
        let image = new Image();
        image.src = URL.createObjectURL(file);
        image.onload = function() {
          let imageWidth = image.width;
          let imageHeight = image.height;
          let canvas = document.createElement('canvas');

          if (width && height) {
            canvas.width = width;
            canvas.height = height;
          } else if (width) {
            canvas.width = width;
            canvas.height = Math.floor(imageHeight * width / imageWidth)
          } else if (height) {
            canvas.width = Math.floor(imageWidth * height / imageHeight);
            canvas.height = height;
          } else {
            canvas.width = imageWidth;
            canvas.height = imageHeight
          }

          var ctx = canvas.getContext("2d");
          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

          let dataUrl = canvas.toDataURL("image/jpeg", quality);
          resolve(dataUrl);
        }
      }).then(dataUrl => {
        return dataUrl;
      })
    },
    savePhoto(imageBlob) {
      let self = this;
      // this.resizeFile(imageBlob).then(dataUrl => {
      //
      //   Axios.post('/fe/card/upload-files', {file: dataUrl}).then(r => {
      //     if (r?.data?._status) {
      //       self.photos.push({
      //         id: r.data.file_id,
      //         url: URL.createObjectURL(imageBlob)
      //       })
      //       self.selectedImages.push(r.data.file_id)
      //     }
      //   })
      //
      // })

      let reader = new FileReader();
      reader.readAsDataURL(imageBlob);

      reader.onload = function() {
        Axios.post('/fe/card/upload-files', {file: reader.result}).then(r => {
          if (r?.data?._status) {
            self.photos.push({
              id: r.data.file_id,
              url: URL.createObjectURL(imageBlob)
            })
            self.selectedImages.push(r.data.file_id)
          }
        })
      };

      // let id = Math.random()
      // this.photos.push({
      //   id: id,
      //   url: URL.createObjectURL(imageBlob)
      // })
      // this.selectedImages.push(id)

    },
    async startVideo() {
      if (this.activeVideoStream) {
        this.activeVideoStream.getTracks().forEach(track => track.stop());
      }
      try {
        this.activeVideoStream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: this.currentCameraMode }
        });
        document.getElementById("preview").srcObject = this.activeVideoStream;
      } catch (error) {
        this.saveError(error)
      }
    },
  }
}
</script>

<style scoped>
.user-mode {
  transform: scaleX(-1)
}

.switch-camera {
  position: absolute;
  z-index: 9999;
  cursor: pointer;
  right: 30px;
  transform: translateY(15px);
}

.switch-camera-wrapper {
  background-color: white;
  border-radius: 100px;
  width: 50px;
  height: 50px;
}

.switch-camera .bx-camera {
  color: black;
  font-size: 26px;
  transform: translate(12px, 13px);

}

.switch-camera .switch-number {
  color: black;
  width: 10px;
  transform: translate(34px, -28px);
  font-weight: bold;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.image-section {
  background-color: white;
  color: black;
  border-radius: 3px;
  font-weight: bold;
  padding: 9px;
  cursor: pointer;
}

.preview-img-remove {
  position: absolute !important;
  font-size: 25px !important;
  cursor: pointer;
  color: red;
  transform: translate(74px, 16px);
}

.active-preview-image {
  border: 3px solid #5e79ff
}
.preview-label {
  position: absolute !important;
  height: 25px !important;
  cursor: pointer;
  transform: translate(20px, 16px);
}

.preview-label span.icon {
  background-color: #f8f8f9 !important;
}

.preview-label span.icon i{
  transform: translateY(-1px);
}

.preview-label input[type=checkbox]:checked+span.icon  {
  background-color: #5e79ff !important;
  border: 2px solid #fff !important;
}

.preview-img-gallery {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.preview-img {
  width: 100px;
  height: 100px;
  border-radius: 20px;
  margin: 10px;
  cursor: pointer;
}

.web-video-containter-full-width {
  width: 100%;
}

.web-video-containter {
  min-height: 93vh;
  height: auto;
  z-index: 100001;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #1C1F3A;
}
@media (orientation: landscape) {
  .web-video-containter {
    width: 100vw;
    height: 100%;
    overflow: hidden;
    min-height: auto;
  }
  .actions{
    flex-direction: column-reverse;
    position: absolute !important;
    right: 0 !important;
    left: auto !important;
    height: 82% !important;
    width: 100px;
  }
  .actions .image-section{
    font-size: 10px;
    width: 85px;
    position: relative;
    top: -5px;
  }
  .switch-number{
    transform: translate(24px, -25px) !important;
    font-size: 12px;
  }
  .actions .waves-light{
    position: relative;
    top: -20px;
  }
  .btn-shot{
    position: relative;
    top: -20px;
  }
  .bx-camera{
    font-size: 21px !important;
    transform: translate(10px, 12px) !important;
  }
  .switch-camera-wrapper{
    width: 40px;
    height: 40px;
  }
}
#preview {
  flex: 1;
  width: 100%;
  /*height: 100%;*/
  /*max-height: 100vh;*/
  object-fit: cover;
  height: 93vh;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 10vh;
}

#take-photo {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50px;
  background-color: grey;
  border: 3px solid white;
}

.red-take-photo {
  background-color: red !important;
}

</style>
