import Axios from "axios";
import NProgress from 'nprogress';
import _ from "lodash";

export const Actions = {
  loadData(context, isShowNProgress = false) {
        if (isShowNProgress) {
            NProgress.start();
        }
        let request =Axios.post('/fe/dashboard/task', {
            showOldTasks: context.state.showOldTasks,
            showOldComments: context.state.showOldComments,
            showOldEmails: context.state.showOldEmails,
        }).then(response => {
            if (response.data._status) {
                context.commit('loadTasks', response.data.tasks);
                context.commit('loadComments', response.data.comments);
                context.commit('loadEmails', response.data.emails);
            context.commit('loadSms', response.data.sms);}
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            NProgress.done();
        });return request
  },
  loadNotices(context) {
    Axios.get("/fe/adv/notices")
        .then(response => {
          if (response.data._status) {
            _.forEach(response.data.promos, function (promo) {
              context.commit('loadPromos', promo);
            })
            //to remove the promo if there is an update
            context.commit('filterPromos', response.data.promos);
            context.commit('loadNews', response.data.news);
            context.commit('loadTeam', response.data.team);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  setShowCountTasks(context, count) {
    context.commit('dashboardSetShowCountTasks', parseInt(count));
  },
  setShowCountCards(context, count) {
    context.commit('dashboardSetShowCountCards', parseInt(count));
  },
  setShowCountComments(context, count) {
    context.commit('dashboardSetShowCountComments', parseInt(count));
  },
  setShowCountEmails(context, count) {
    context.commit('dashboardSetShowCountEmails', parseInt(count));
  },
  setShowCountSms(context, count) {
    context.commit('dashboardSetShowCountSms', parseInt(count));
  },
  setShowOldTasks(context, show) {
    context.commit('dashboardSetShowOldTasks', !!show);
  },
  setShowOldComments(context, show) {
    context.commit('dashboardSetShowOldComments', !!show);
  },
  setShowOldEmails(context, show) {
    context.commit('dashboardSetShowOldEmails', !!show);
  },
  setShowOldSms(context, show) {
    context.commit('dashboardSetShowOldSms', !!show);
  },
  setIsShowLastTasks(context, show) {
    context.commit('dashboardSetIsShowLastTasks', !!show);
  },
  setIsShowLastComments(context, show) {
    context.commit('dashboardSetIsShowLastComments', !!show);
  },
  setIsShowLastEmails(context, show) {
    context.commit('dashboardSetIsShowLastEmails', !!show);
  }
};
