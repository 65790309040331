<template>
  <div class="row mt-1" :class="{'no-gutter': isMobile}" >
    <div class="orders-body d-flex">
      <div class="orders-summary">
        <div class="orders-header">
          Summary
        </div>
        <div class="orders-summary-info">
          <div class="orders-summary-info-item d-flex">
            <div class="orders-summary-info-item-name">Vehicle:</div>
            <div class="orders-summary-info-item-user">{{card.vehicle.make}} {{card.vehicle.model}}</div>
          </div>
          <div class="orders-summary-info-item d-flex">
            <div class="orders-summary-info-item-name">Insurer:</div>
            <div class="orders-summary-info-item-user">{{ card.insurance.insurer_name ? card.insurance.insurer_name : 'Non-Insurance'}}</div>
          </div>

          <div class="orders-summary-using">
            Ordered Using: PARTSSEARCH
          </div>
        </div>
        <div class="orders-summary-add-manual-order">
          <button @click="addManual" type="button" class="tab-header-orders btn" style="cursor: pointer">
            Add Manual Order
          </button>
        </div>
      </div>
      <div class="orders-section">
        <div class="orders-header">
          Orders
        </div>
        <div class="orders-section-body" v-for="order in computedOrders" :key="order.id">
          <div class="orders-section-item">
            <div class="orders-section-item-header d-flex">
              <div class="orders-section-item-header-elem d-flex">
                <div class="orders-section-item-header-elem_name">Order Number:</div>
                <input v-if="isManual(order)" @focus="onFocusOrderDetails(order.id, 'number')" @blur="onBlurOrderDetails(order)" v-model="order.number" :disabled="!isStatusActive" @change="saveOrder(order)" class="form-control"/>
                <div v-else class="orders-section-item-header-elem_info">{{order.number}}</div>
              </div>
              <div class="orders-section-item-header-elem d-flex">
                <div class="orders-section-item-header-elem_name">Type:</div>
                <input v-if="isManual(order)" @focus="onFocusOrderDetails(order.id, 'type')" @blur="onBlurOrderDetails(order)" v-model="order.type" :disabled="!isStatusActive" @change="saveOrder(order)" class="form-control"/>
                <div v-else class="orders-section-item-header-elem_info">Parts</div>
              </div>
              <div class="orders-section-item-header-elem d-flex">
                <div class="orders-section-item-header-elem_name">Supplier:</div>
                <div >
<!--                  <multiselect-->
<!--                      v-if="isManual(order)"-->
<!--                      v-model="order.supplierId"-->
<!--                      :options="suppliersForSelect"-->
<!--                      :showLabels="false"-->
<!--                      :option-height="29"-->
<!--                      :max-height="203"-->
<!--                      :close-on-select="true"-->
<!--                      :disabled="!isStatusActive"-->
<!--                      openDirection="bottom"-->
<!--                      style="min-width: 200px; opacity: 1"-->
<!--                      track-by="id"-->
<!--                      @input="saveOrder(order)"-->
<!--                      label="business_name"-->
<!--                  >-->
<!--                  </multiselect>-->
                  <input v-if="isManual(order)" v-model="order.supplier" @focus="onFocusOrderDetails(order.id, 'supplier')" @blur="onBlurOrderDetails(order)" :disabled="!isStatusActive" @change="saveOrder(order)" class="form-control"/>
                  <span v-else class="orders-section-item-header-elem_info">{{getSupplier(order.supplierId)}}</span>
                </div>
              </div>
              <div class="orders-section-item-header-elem d-flex">
                <div class="orders-section-item-header-elem_name">Order Date:</div>
                <div class="orders-section-item-header-elem_time">
                  <date-picker v-if="isManual(order)" :is-disabled="!isStatusActive" v-model="order.orderDate" @onChange="saveOrder(order)"></date-picker>
                  <span v-else>
                    <span v-if="order.dateDue == null">Next available run</span>
                    <span v-else-if="order.dateDue">{{order.dateDue | formatDate}}</span>
                  </span>
                </div>
              </div>
              <div class="orders-section-item-header-elem d-flex">
                <div class="orders-section-item-header-elem_name">Delivery Date:</div>
                <div class="orders-section-item-header-elem_time">
                  <date-picker v-if="isManual(order)" :is-disabled="!isStatusActive" v-model="order.deliveryDate"  @onChange="saveOrder(order)"></date-picker>
                  <span v-else>
                    <span v-if="order.dateDue == null && order.supplierDeliveryDate == null">Next available run</span>
                    <span v-else-if="order.supplierDeliveryDate">{{order.supplierDeliveryDate  | formatDate}}</span>
                  </span>
                </div>
              </div>
            </div>
            <div class="orders-section-item-header d-flex" v-if="isManual(order)">
              <button @click="onKeyPressEnter(order.id)" type="button" class="tab-header-orders btn" style="cursor: pointer">
                Add Manual Part
              </button>
              <i
                  @click="removeOrder(order.id)"
                  style="color: red; font-size: 22px; cursor: pointer; margin-left: 15px;"
                  class='bx bx-trash'></i>
            </div>
          </div>
          <div class="table-orders">
            <div class="orders-table-header d-flex">
              <div class="orders-table-header-item"></div>
              <div class="orders-table-header-item">Part Description</div>
              <div class="orders-table-header-item">Part Number</div>
              <div class="orders-table-header-item">Type</div>
              <div class="orders-table-header-item">Qty Ord</div>
              <div class="orders-table-header-item">Qty Rcvd</div>
              <div class="orders-table-header-item">Cost Price</div>
              <div class="orders-table-header-item">List</div>
              <div class="orders-table-header-item">Invoice Nbr</div>
              <div class="orders-table-header-item">Total</div>
            </div>
            <div class="orders-table-body">
              <draggable v-model="isManual(order) ? order.items : order.parts"
                         class="dragArea"
                         tag="div"
                         handle=".handle"
                         @change="saveDraggable(order.id)"
                         :animation="150"
                         :disabled="isMobile || !isStatusActive || !isManual(order)"
                         :group="{
                                            name: 'items',
                                            put: true // not allow(false) or allow(true) items to be put into this list
                                        }"
                         ghost-class="ghost-items"
              >
                <div v-for="(item, index) in (isManual(order) ? order.items : order.parts)" class="handle orders-table-body-row d-flex">
                  <div class="orders-table-body-row-icon orders-table-body-row-item">
                    <div class="d-flex">
                      <template v-if="isManual(order)">
                        <i class='bx bx-edit-alt' style="cursor: pointer"></i>
                        <i @click="removePrt(order.id, item, index)" class='bx bx-trash' style="cursor: pointer"></i>
                      </template>
                      <template v-else>
                        <i :class="[getIconClass(item, order),
                           {
                             'gray-icon': getIconClass(item, order).includes('bx-package') && item.stock !== 'InStock' && item.stock !== 'ExStock',
                             'text-success': getIconClass(item, order).includes('bx-package') && item.stock === 'InStock',
                             'processing-icon-orange': getIconClass(item, order).includes('bx-package') && item.stock === 'ExStock'
                           }]"
                           v-b-tooltip.hover="{customClass: 'ps-tooltip', title: getNameIcon(item, order), placement:'leftbottom'}"></i>
                        <i @click="redirectToPSOrder(order.id)" v-b-tooltip.hover="{customClass: 'ps-tooltip', title: true ? 'View in Partsearch' : null, placement:'leftbottom'}" :style="{color: true ? '#2f6fa9' : '#c6c6c6'}" style="cursor: pointer" class='bx bx-log-in'></i>
                        <i v-b-tooltip.hover="{customClass: 'ps-tooltip', title: getTextForPricingIcon(item), placement:'leftbottom'}" :style="{color: getColorForPricingIcon(item)}" class='bx bx-dollar-circle'></i>
                        <i v-b-tooltip.hover="{customClass: 'ps-tooltip', title: false ? 'Message received' : null, placement:'leftbottom'}" style="color: #c6c6c6" class='bx bx-chat'></i>
                      </template>
                    </div>
                  </div>
                  <div class="orders-table-body-row-part-description orders-table-body-row-item">
                    <template v-if="isManual(order)">
                      <input
                          :ref="'part-description-' + order.id + '-' + index"
                          v-model="item.description"
                          :disabled="!isStatusActive"
                          @keydown.left="onKeyPressLeft('part-description-' + order.id + '-' + index, $event)"
                          @keydown.right="onKeyPressRight('part-description-' + order.id + '-' + index, $event)"
                          @keydown.up="onKeyPressUp('part-description-'  + order.id + '-' + index)"
                          @keydown.down="onKeyPressDown('part-description-' + order.id + '-' + index)"
                          @focus="onFocus('part-description-' + order.id + '-' + index, order.id, 'description', item.id)"
                          @change="saveOrderPart(order.id, item)"
                          @blur="onBlur(item, 'partDescription')"
                          @keypress.enter="onKeyPressEnter(order.id)"
                          class="form-control"/>
                    </template>
                    <template v-else>
                      {{ item.partDescription }}
                    </template>
                  </div>
                  <div class="orders-table-body-row-part-number orders-table-body-row-item">
                    <template v-if="isManual(order)">
                      <input
                          :ref="'part-number-' + order.id + '-' + index"
                          v-model="item.number"
                          :disabled="!isStatusActive"
                          @keydown.left="onKeyPressLeft('part-number-' + order.id + '-' + index, $event)"
                          @keydown.right="onKeyPressRight('part-number-' + order.id + '-' + index, $event)"
                          @keydown.up="onKeyPressUp('part-number-'+ order.id + '-' + index)"
                          @change="saveOrderPart(order.id, item)"
                          @keydown.down="onKeyPressDown('part-number-'+ order.id + '-' + index)"
                          @focus="onFocus('part-number-' + order.id + '-' + index, order.id, 'number', item.id)"
                          @blur="onBlur(item, 'partNumber')"
                          @keypress.enter="onKeyPressEnter(order.id)"
                          class="form-control"/>
                    </template>
                    <template v-else>
                      {{ item.partNumber }}
                    </template>
                  </div>
                  <div class="orders-table-body-row-type orders-table-body-row-item">
                    <template v-if="isManual(order)">
                      <multiselect
                          :ref="'type-' + order.id + '-' + index"
                          v-model="item.type"
                          :options="optionsPartsType"
                          :showLabels="false"
                          :option-height="29"
                          @onKeyLeft="onKeyPressLeft('type-'+ order.id + '-' + index)"
                          @onKeyRight="onKeyPressRight('type-'+ order.id + '-' + index)"
                          :max-height="203"
                          :close-on-select="true"
                          :disabled="!isStatusActive"
                          style="opacity: 1"
                          @open="currentOrderId = order.id"
                          @input="saveOrderPart(order.id, item)"
                          openDirection="bottom"
                      >
                      </multiselect>
                    </template>
                    <template v-else>
                      {{ order.partsType }}
                    </template>
                  </div>
                  <div class="orders-table-body-row-qty-ord orders-table-body-row-item">
                    <template v-if="isManual(order)">
                      <number-formatter
                          :ref="'qty-ord-' + order.id + '-' + index"
                          v-model="item.qtyOrdered"
                          :isDisabled="!isStatusActive"
                          type="text"
                          class="form-control"
                          format="0,0"
                          @onKeyRight="event => onKeyPressRight('qty-ord-' + order.id + '-' + index, event.event)"
                          @onKeyLeft="event => onKeyPressLeft('qty-ord-' + order.id + '-' + index, event.event)"
                          @onKeyUp="onKeyPressUp('qty-ord-'+ order.id + '-' + index)"
                          @onKeyDown="onKeyPressDown('qty-ord-'+ order.id + '-' + index)"
                          @blur="onBlur(item, 'qty_ord')"
                          @onFocus="onFocus('qty-ord-' + order.id + '-' + index, order.id, 'qtyOrdered', item.id)"
                          @onChange="saveOrderPart(order.id, item)"
                          @onKeyEnter="onKeyPressEnter(order.id)"
                      >
                      </number-formatter>
                    </template>
                    <template v-else>
                      {{ item.qty }}
                    </template>
                  </div>
                  <div class="orders-table-body-row-qty-rcvd orders-table-body-row-item">
                    <template v-if="isManual(order)">
                      <number-formatter
                          :ref="'qty-rcvd-' + order.id + '-' + index"
                          v-model="item.qtyReceived"
                          :isDisabled="!isStatusActive"
                          type="text"
                          class="form-control"
                          format="0,0"
                          @onKeyRight="event => onKeyPressRight('qty-rcvd-' + order.id + '-' + index, event.event)"
                          @onKeyLeft="event => onKeyPressLeft('qty-rcvd-' + order.id + '-' + index, event.event)"
                          @onKeyUp="onKeyPressUp('qty-rcvd-'+ order.id + '-' + index)"
                          @onKeyDown="onKeyPressDown('qty-rcvd-'+ order.id + '-' + index)"
                          @blur="onBlur(item, 'qty_rcvd')"
                          @onChange="saveOrderPart(order.id, item)"
                          @onFocus="onFocus('qty-rcvd-' + order.id + '-' + index, order.id, 'qtyReceived', item.id)"
                          @onKeyEnter="onKeyPressEnter(order.id)"
                      >
                      </number-formatter>
                    </template>
                    <template v-else>
                      <span v-if="(item.status == 'Arrived' || item.status == 'Received') && item.qtyReceived == null">
                        {{item.qty }}
                      </span>
                      <span v-else>
                         {{ item.qtyReceived }}
                      </span>
                    </template>
                  </div>
                  <div class="orders-table-body-row-cost-price orders-table-body-row-item">
                    <template v-if="isManual(order)">
                      <number-formatter
                          :ref="'cost-price-' + order.id + '-' + index"
                          v-model="item.cost"
                          class="form-control"
                          :isDisabled="!isStatusActive"
                          type="text"
                          format="$0,0.00"
                          @onKeyRight="event => onKeyPressRight('cost-price-' + order.id + '-' + index, event.event)"
                          @onKeyLeft="event => onKeyPressLeft('cost-price-' + order.id + '-' + index, event.event)"
                          @onKeyUp="onKeyPressUp('cost-price-'+ order.id + '-' + index)"
                          @onKeyDown="onKeyPressDown('cost-price-'+ order.id + '-' + index)"
                          @onChange="saveOrderPart(order.id, item)"
                          @blur="onBlur(item, 'cost_price')"
                          @onFocus="onFocus('cost-price-' + order.id + '-' + index, order.id, 'cost', item.id)"
                          @onKeyEnter="onKeyPressEnter(order.id)"
                      >
                      </number-formatter>
                    </template>
                    <template v-else>
                      {{item.price | formatMoney }}
                    </template>
                  </div>
                  <div class="orders-table-body-row-list orders-table-body-row-item">
                    <template v-if="isManual(order)">
                      <number-formatter
                          :ref="'list-' + order.id + '-' + index"
                          v-model="item.list"
                          :isDisabled="!isStatusActive"
                          class="form-control"
                          type="text"
                          format="$0,0.00"
                          @onKeyRight="event => onKeyPressRight('list-' + order.id + '-' + index, event.event)"
                          @onKeyLeft="event => onKeyPressLeft('list-' + order.id + '-' + index, event.event)"
                          @onKeyUp="onKeyPressUp('list-'+ order.id + '-' + index)"
                          @onKeyDown="onKeyPressDown('list-'+ order.id + '-' + index)"
                          @blur="onBlur(item, 'list')"
                          @onFocus="onFocus('list-' + order.id + '-' + index, order.id, 'list', item.id)"
                          @onChange="saveOrderPart(order.id, item)"
                          @onKeyEnter="onKeyPressEnter(order.id)"
                      >
                      </number-formatter>
                    </template>
                    <template v-else>
                      {{ item.rrp | formatMoney }}
                    </template>
                  </div>
                  <div class="orders-table-body-row-invoice-nbr orders-table-body-row-item">
                    <template v-if="isManual(order)">
                      <input
                          :ref="'invoice-nbr-' + order.id + '-' + index"
                          v-model="item.invoiceNumber"
                          :disabled="!isStatusActive"
                          @keydown.left="onKeyPressLeft('invoice-nbr-' + order.id + '-' + index, $event)"
                          @keydown.right="onKeyPressRight('invoice-nbr-' + order.id + '-' + index, $event)"
                          @keydown.up="onKeyPressUp('invoice-nbr-'+ order.id + '-' + index)"
                          @keydown.down="onKeyPressDown('invoice-nbr-'+ order.id + '-' + index)"
                          @focus="onFocus('invoice-nbr-' + order.id + '-' + index, order.id, 'invoiceNumber', item.id)"
                          @blur="onBlur(item, 'invoice_nbr')"
                          @keypress.enter="onKeyPressEnter(order.id)"
                          @change="saveOrderPart(order.id, item)"
                          class="form-control"/>
                    </template>
                    <template v-else>
                      {{ item.invoiceNumber }}
                    </template>
                  </div>
                  <div class="orders-table-body-row-total orders-table-body-row-item">
                    <template v-if="isManual(order)">
                      <number-formatter
                          :ref="'total-' + order.id + '-' + index"
                          v-model="item.totalManual"
                          :isDisabled="!isStatusActive"
                          class="form-control"
                          format="$0,0.00"
                          @onKeyRight="event => onKeyPressRight('total-' + order.id + '-' + index, event.event)"
                          @onKeyLeft="event => onKeyPressLeft('total-' + order.id + '-' + index, event.event)"
                          @onKeyUp="onKeyPressUp('total-'+ order.id + '-' + index)"
                          @onChange="saveOrderPart(order.id, item)"
                          @onKeyDown="onKeyPressDown('total-'+ order.id + '-' + index)"
                          @blur="onBlur(item, 'total')"
                          @onFocus="onFocus('total-' + order.id + '-' + index, order.id, 'totalManual', item.id)"
                          @onKeyEnter="onKeyPressEnter(order.id)"
                      >
                      </number-formatter>
                    </template>
                    <template v-else>
                      {{ item.total | formatMoney }}
                    </template>
                  </div>
                </div>
              </draggable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NumberFormatter from '../../utility/number-formatter'
import Multiselect from 'vue-multiselect'
import draggable from 'vuedraggable'
import datePicker from '@/components/utility/date-picker.vue'

const rsMsMixin = {
  mounted() {
    const searchInput = this.$refs.search;
    if (searchInput) {
      searchInput.addEventListener('keydown', this.handleKeydown);
    }
  },
  beforeDestroy() {
    const searchInput = this.$refs.search;
    if (searchInput) {
      searchInput.removeEventListener('keydown', this.handleKeydown);
    }
  },
  methods: {
    handleKeydown(e) {
      if (e.keyCode === 37) {
        e.preventDefault();
        this.$emit('onKeyLeft');
        //   e.stopPropagation();

      } else if (e.keyCode === 39) {
        e.preventDefault();
        this.$emit('onKeyRight');
        //  e.stopPropagation();

      } else if (e.keyCode === 13) {
        var vm = this;
        setTimeout(() => {
          vm.$emit('onKeyRight');
        }, 50);
      }
    }
  }
};

Multiselect.mixins.push(rsMsMixin);

import {isMobile} from '../../../deviceDetect/index';
import {mapGetters} from "vuex";
import _ from "lodash";
import {appConfig} from "../../../config";
import dayjs from "dayjs";
import Axios from "axios";
export default {
  name: "the-orders",
  props: {
    card: {
      type: Object,
      default () {
        return {}
      }
    },
    orders: {
      type: Object,
      default () {
        return {}
      }
    },
    estimateId: {
      type: [Number, String],
      default () {
        return 0
      }
    },
    manualOrders: {
      type: Array,
      default () {
        return []
      }
    },
    suppliers: {
      type: Object,
      default () {
        return {}
      }
    },
    parts: {
      type: Array,
      default () {
        return []
      }
    },
  },
  created() {
    this.loadSuppliers()
  },
  components: {
    Multiselect,
    NumberFormatter,
    datePicker,
    draggable
  },
  data() {
    return {
      isMobile,
      currentOrderId: null,
      manualOrdersData: [],
      manualOrdersDataOriginal: [],
      suppliersForSelect: [],
      focusedElement: '',
      focusedElementDetailes: null,
      focusedOrderDetailes: null,
      optionsPartsType: ['None', 'OEM-GEN', 'OEM-PAR', 'OEM-ALT', 'OEM-ALT/AFTM', 'CERT-AFTM', 'AFTM', 'AFTM/USED', 'USED', 'RECO',],
    }
  },
  computed: {
    ...mapGetters({
      isStatusActive: 'isStatusActive',
    }),
    computedAvailableRefs () {
      let order = _.find(this.computedOrders, (o) => {return Number(o.id) == Number(this.currentOrderId)})
      let result = []
      if (order) {
        _.forEach(order.items, (part, index) => {
          let cells = ['part-description-' + this.currentOrderId + '-' + index, 'part-number-' + this.currentOrderId + '-' + index, 'type-' + this.currentOrderId + '-' + index, 'qty-ord-' + this.currentOrderId + '-' + index, 'qty-rcvd-' + this.currentOrderId + '-' + index, 'cost-price-' + this.currentOrderId + '-' + index,
            'list-' + this.currentOrderId + '-' + index, 'invoice-nbr-' + this.currentOrderId + '-' + index, 'total-' + this.currentOrderId + '-' + index, ]
          result.push(cells)
        })
      }
      return result
    },
    computedOrders() {
      let r = [];
      _.forIn(this.orders, (itm) => {
          r.push(itm)
      })

      return _.orderBy(_.concat(r, this.manualOrdersData), [(itm) => new Date(itm.createdOn).getTime()],['desc']);
    },
    computedSuppliers() {
      let r = [];
      _.forIn(this.suppliers, (itm) => {
        r.push(itm)
      })

      return r
    }
  },
  watch: {
    manualOrders: {
      handler(v) {
        let result = _.cloneDeep(v)
        this.manualOrdersDataOriginal = _.cloneDeep(v)
        _.forEach(result, (o) => {
          if (o.deliveryDate) {
            o.deliveryDate = dayjs(new Date(o.deliveryDate).getTime()).format('YYYY-MM-DD').split('-').reverse().join('/');
          }
          if (o.orderDate) {
            o.orderDate = dayjs(new Date(o.orderDate).getTime()).format('YYYY-MM-DD').split('-').reverse().join('/');
          }
        })

        if (this.focusedElementDetailes || this.focusedOrderDetailes) {
          let r = []
          _.forEach(result, (order) => {
            if (this.focusedElementDetailes) {
              if (Number(order.id) == Number(this.focusedElementDetailes.orderId)) {
                let o = _.cloneDeep(order)
                let prtIndex = _.findIndex(o.items, (itm) => {
                  return Number(itm.id) == Number(this.focusedElementDetailes.prtId)
                })
                let orderOrigin = _.findIndex(this.manualOrdersData, (itm) => {
                  return Number(itm.id) == Number(this.focusedElementDetailes.orderId)
                })

                if (orderOrigin !== -1 && prtIndex !== -1 ) {
                  let prtIndexOrigin = _.findIndex(this.manualOrdersData[orderOrigin].items, (itm) => {
                    return Number(itm.id) == Number(this.focusedElementDetailes.prtId)
                  })
                  if (prtIndexOrigin !== -1) {
                    o.items[prtIndex][this.focusedElementDetailes.field] = this.manualOrdersData[orderOrigin].items[prtIndexOrigin][this.focusedElementDetailes.field]
                    r.push(o)
                  } else {
                    r.push(order)
                  }
                } else {
                  r.push(order)
                }
              } else {
                r.push(order)
              }
            } else {
              let field = this.focusedOrderDetailes.field
              if (Number(order.id) == Number(this.focusedOrderDetailes.orderId)) {
                let orderOrigin = _.find(this.manualOrdersData, (itm) => {
                  return Number(itm.id) == Number(this.focusedOrderDetailes.orderId)
                })
                if (orderOrigin) {
                  let o = _.cloneDeep(order)
                  o[field] = orderOrigin[field]
                  r.push(o)
                } else {
                  r.push(order)
                }
              } else {
                r.push(order)
              }
            }
          })
          this.manualOrdersData = r
        } else {
          this.manualOrdersData = result
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    loadSuppliers: function () {
      Axios.get('/fe/supplier', {
        params: {
          pageNum: 1,
          sort_by: 'supplier_id',
          sort_type: 'DESC'
        }
      })
          .then(response => {
            this.suppliersForSelect = response.data.data;
          })
          .catch(error => {
            console.log(error);
          });
    },
    removeOrder(id) {
      this.$emit('autoSaveMessageUpdate', 'Saving...')
      Axios.post('/fe/estimate/manual-orders/delete', {orderId: id}).then(r => {
        if (r.data._status) {
          this.$emit('removeOrder', id)
          this.$emit('autoSaveMessageUpdate', 'Saved')
        } else {
          this.$emit('autoSaveMessageUpdate', 'Unsaved Changes')
        }
      }).catch(() => {
        this.$emit('autoSaveMessageUpdate', 'Unsaved Changes')
      })
    },
    removePrt(orderId, prt, index) {
      this.deleteOrderPart(orderId, prt.id)
    },
    saveOrder(order) {
      let orderOriginal = _.find(this.manualOrdersDataOriginal, (o) => {
        return Number(o.id) == Number(order.id)
      })
      if (orderOriginal) {
        let b = false
        if ((!orderOriginal.deliveryDate && order.deliveryDate) || (orderOriginal.deliveryDate && dayjs(new Date(orderOriginal.deliveryDate).getTime()).format('YYYY-MM-DD').split('-').reverse().join('/') != order.deliveryDate)) {
          b = true
        }

        if ((!orderOriginal.orderDate && order.orderDate) || (orderOriginal.orderDate && dayjs(new Date(orderOriginal.orderDate).getTime()).format('YYYY-MM-DD').split('-').reverse().join('/') != order.orderDate)) {
          b = true
        }

        if ((!orderOriginal.number && order.number) || (orderOriginal.number && orderOriginal.number != order.number)) {
          b = true
        }
        if ((!orderOriginal.type && order.type) || (orderOriginal.type && orderOriginal.number != order.type)) {
          b = true
        }
        if ((!orderOriginal.supplier && order.supplier) || (orderOriginal.supplier && orderOriginal.number != order.supplier)) {
          b = true
        }
        if (!b) {
          return
        }
      }
      this.$emit('autoSaveMessageUpdate', 'Saving...')
      Axios.post(`/fe/estimate/manual-orders/${order.id}/update`, {
        number: order.number,
        type: order.type,
        supplier: order.supplier,
        deliveryDate: order.deliveryDate ? order.deliveryDate.split('/').reverse().join('-') : order.deliveryDate,
        orderDate: order.orderDate ? order.orderDate.split('/').reverse().join('-') : order.orderDate,
      }).then((r) => {
        this.$emit('autoSaveMessageUpdate', 'Saved')
      })
          .catch(() => {
            this.$emit('autoSaveMessageUpdate', 'Unsaved Changes')
          })
    },
    saveOrderPart(orderId, part) {
      this.$emit('autoSaveMessageUpdate', 'Saving...')
      Axios.post(`/fe/estimate/manual-orders/items/${part.id}/update`, {
        "description": part.description,
        "number": part.number,
        "type": part.type,
        "qtyOrdered": part.qtyOrdered,
        "qtyReceived": part.qtyReceived,
        "cost": part.cost,
        "list": part.list,
        "invoiceNumber": part.invoiceNumber,
        "totalManual": part.totalManual,

      }).then((r) => {
        this.$emit('autoSaveMessageUpdate', 'Saved')
      })
          .catch(() => {
            this.$emit('autoSaveMessageUpdate', 'Unsaved Changes')
          })
    },
    deleteOrderPart(orderId, prtId) {
      this.$emit('autoSaveMessageUpdate', 'Saving...')
      Axios.post('/fe/estimate/manual-orders/items/delete', {itemId: prtId}).then(r => {
        if (r.data._status) {
          this.$emit('removePrt', {orderId: orderId, prtId: prtId})
          this.$emit('autoSaveMessageUpdate', 'Saved')
        } else {
          this.$emit('autoSaveMessageUpdate', 'Unsaved Changes')
        }
      })
          .catch(() => {
            this.$emit('autoSaveMessageUpdate', 'Unsaved Changes')
          })
    },
    isManual(order) {
      return !!_.find(this.manualOrdersData, (o) => {
        return Number(o.id) == Number(order.id)
      })
    },
    addManual() {
      this.$emit('autoSaveMessageUpdate', 'Saving...')
      Axios.post(`/fe/estimate/${this.estimateId}/manual-orders/add`, {}).then((r) => {
        if (r.data._status && r.data.order) {
          this.$emit('addManual', r.data.order)
          this.$emit('autoSaveMessageUpdate', 'Saved')
        } else {
          this.$emit('autoSaveMessageUpdate', 'Unsaved Changes')
        }
      })
          .catch(() => {
            this.$emit('autoSaveMessageUpdate', 'Unsaved Changes')
          })
    },
    getColorForPricingIcon(prt) {
      let color = '#c6c6c6'
      let origPart = _.find(this.parts, (p) => {
        return Number(p.id) == Number(prt.repairshopPartId)
      })

      if (origPart) {
        if (Number(origPart.total) > Number(prt.total)) {
          color = 'red'
        } else if (Number(origPart.total) < Number(prt.total)) {
          color = 'green'
        }
      }
      return color
    },
    getTextForPricingIcon(prt) {
      let text = ''
      let origPart = _.find(this.parts, (p) => {
        return Number(p.id) == Number(prt.repairshopPartId)
      })

      if (origPart) {
        if (Number(origPart.total) > Number(prt.total)) {
          text = 'Pricing decrease received'
        } else if (Number(origPart.total) < Number(prt.total)) {
          text = 'Pricing increase received'
        }
      }

      return text
    },
    redirectToPSOrder(id) {
      let url = appConfig.appPartsSearchURL + '/r/order/' + id
      window.open(url, '_blank').focus()
    },
    statusList (order) {
      let result = [
        {
          id: 'Created',
          name: 'Order Processed',
          nameClass: 'text-success',
          color: 'info',
          iconClass: 'bx bx-package mr-0',
          tooltip: 'Ordered'
        },
        {
          id: 'Shipped',
          name: 'Shipped',
          nameClass: 'text-primary',
          color: 'warning',
          iconClass: 'bx bxs-truck text-primary'
        },
        {
          id: 'Received',
          name: 'Received',
          nameClass: 'text-success',
          color: 'success',
          iconClass: 'bx bx-buildings',
          tooltip: 'Received'
        },
        {
          id: 'Arrived',
          name: 'Arrived',
          nameClass: 'text-success',
          color: 'success',
          iconClass: 'bx bx-buildings',
          tooltip: 'Arrived'
        },
        {
          id: 'DamagedPartExWarehouse',
          name: 'Damage Ex Warehouse',
          nameClass: 'text-secondary',
          color: 'danger',
          iconClass: 'bx bxs-factory text-warning',
          tooltip: 'Damaged Part Ex Warehouse'
        },
        {
          id: 'IncorrectPartDelivered',
          name: 'Incorrect Part Delivered',
          nameClass: 'text-danger',
          color: 'bg-warning',
          iconClass: 'bx bxs-flag-alt text-warning',
          tooltip: 'Incorrect'
        },
        {
          id: 'IncorrectPartOrdered',
          name: 'Incorrect Part Ordered',
          nameClass: 'text-danger',
          color: 'bg-warning',
          iconClass: 'bx bxs-flag-alt incorrect-part-ordered-icon',
          tooltip: 'Incorrect Ordered'
        },

        {
          id: 'CancelPart',
          name: 'Cancelled',
          nameClass: 'cancel-part-class',
          color: 'danger',
          iconClass: 'bx bxs-flag-alt text-danger',
          tooltip: 'Cancelled'
        },
        {
          id: 'NoLongerNeeded',
          name: 'No Longer Needed',
          nameClass: 'text-warning',
          color: 'danger',
          iconClass: 'bx bxs-flag-alt text-warning',
          tooltip: 'No Longer Needed'
        },
        {
          id: 'ChangeInRepairMethod',
          name: 'Change in Repair Method',
          nameClass: 'text-warning',
          color: 'danger',
          iconClass: 'bx bxs-flag-alt text-warning',
          tooltip: 'Change in Repair Method'
        },
        {
          id: 'ReturnDamagedPart',
          name: 'Returned',
          nameClass: 'text-secondary',
          color: 'danger',
          iconClass: 'bx font-24 bx-transfer-alt text-warning',
          tooltip: 'Returned'
        },
        {
          id: 'DamagedPartExWarehouse',
          name: 'Mark Damaged Part Ex Warehouse',
          nameClass: 'text-secondary',
          color: 'danger',
          iconClass: 'bx bxs-factory text-warning',
          tooltip: 'Damaged Part Ex Warehouse'
        },
        {
          id: 'IncorrectPartDelivered',
          name: 'Incorrect Part',
          nameClass: 'text-danger',
          color: 'bg-warning',
          iconClass: 'bx bxs-flag-alt text-warning',
          tooltip: 'Incorrect'
        },
        {
          id: 'IncorrectPartOrdered',
          name: 'Incorrect Part Ordered',
          nameClass: 'text-danger',
          color: 'bg-warning',
          iconClass: 'bx bxs-flag-alt incorrect-part-ordered-icon',
          tooltip: 'Incorrect Ordered'
        },
        {
          id: 'DidNotArrive',
          name: 'Did Not Arrive',
          nameClass: 'text-danger',
          color: 'danger',
          iconClass: 'bx bxs-flag-alt text-danger'
        },
      ]
      if (order.status !== 'Processing') {
        result = _.filter(result, (r) => {
          return r.id !== 'Created'
        })
      }
      return result
    },
    getIconClass: function (item, order) {
      let status = item.status
      let elem = _.find(this.statusList(order), { id: status })
      if (elem) {
        return elem.iconClass
      }
      return ''
    },
    getNameIcon: function (item, order) {
      let status = item.status
      let elem = _.find(this.statusList(order), { id: status })
      if (elem) {
        return elem.name
      }
      return ''
    },
    getSupplier(sId) {
      if (this.suppliers?.[sId]?.businessName) {
        return this.suppliers[sId].businessName
      }
      return ''
    },
    onKeyPressUp (ref) {
      console.log('up', ref)
      let indexOfCurrentRow = _.findIndex(this.computedAvailableRefs, (row) => {
        return row.includes(ref)
      })
      let indexOfCurrentElement = _.findIndex(this.computedAvailableRefs[indexOfCurrentRow], (r) => {
        return r === ref
      })
      let resultRef = null
      if (indexOfCurrentRow === 0) {
        resultRef = this.computedAvailableRefs[this.computedAvailableRefs.length - 1][indexOfCurrentElement]
      } else {
        resultRef = this.computedAvailableRefs[indexOfCurrentRow - 1][indexOfCurrentElement]
      }
      if (!this.$refs[resultRef]) {
        this.onKeyPressUp(resultRef)
        return
      }
      if (resultRef.includes('type-')) {
        this.$refs[resultRef][0].$el.focus()
        return;
      }
      if (this.$refs[resultRef][0]) {
        this.$refs[resultRef][0].focus()
      } else {
        this.$refs[resultRef].focus()
      }
    },
    onKeyPressDown (ref) {
      console.log('down')
      let indexOfCurrentRow = _.findIndex(this.computedAvailableRefs, (row) => {
        return row.includes(ref)
      })
      let indexOfCurrentElement = _.findIndex(this.computedAvailableRefs[indexOfCurrentRow], (r) => {
        return r === ref
      })
      let resultRef = null
      if (indexOfCurrentRow + 1 === this.computedAvailableRefs.length) {
        resultRef = this.computedAvailableRefs[0][indexOfCurrentElement]
      } else {
        resultRef = this.computedAvailableRefs[indexOfCurrentRow + 1][indexOfCurrentElement]
      }
      if (!this.$refs[resultRef]) {
        this.onKeyPressDown(resultRef)
        return
      }
      if (resultRef.includes('type-')) {
        this.$refs[resultRef][0].$el.focus()
        return;
      }
      if (this.$refs[resultRef][0]) {
        this.$refs[resultRef][0].focus()
      } else {
        this.$refs[resultRef].focus()
      }
    },
    onKeyPressLeft (ref, e,) {
      if (e) {
        var elem = e.srcElement
        if (this.getCaretPosition(elem) > 0) {
          return
        }
      }
      let indexOfCurrentRow = _.findIndex(this.computedAvailableRefs, (row) => {
        return row.includes(ref)
      })
      let indexOfCurrentElement = _.findIndex(this.computedAvailableRefs[indexOfCurrentRow], (r) => {
        return r === ref
      })
      let resultRef = null
      if (indexOfCurrentElement === 0) {
        let resultRow = []
        if (indexOfCurrentRow === 0) {
          resultRow = this.computedAvailableRefs[this.computedAvailableRefs.length - 1]
        } else {
          resultRow = this.computedAvailableRefs[indexOfCurrentRow - 1]
        }
        let resultRowLastIndex = resultRow.length - 1
        resultRef = resultRow[resultRowLastIndex]
        if (!this.$refs[resultRef]) {
          this.onKeyPressLeft(resultRef)
          return
        }
      } else {
        resultRef = this.computedAvailableRefs[indexOfCurrentRow][indexOfCurrentElement - 1]
        if (!this.$refs[resultRef]) {
          this.onKeyPressLeft(resultRef)
          return
        }
      }
      if (resultRef.includes('type-')) {
        this.$refs[resultRef][0].$el.focus()
        return;
      }
      if (this.$refs[resultRef]?.$el) {
        this.$refs[resultRef].$el.focus()
      } else if (this.$refs[resultRef][0]) {
        this.$refs[resultRef][0].focus()
      } else {
        this.$refs[resultRef].focus()
      }
    },
    onKeyPressRight (ref, e) {
      if (e) {
        var elem = e.srcElement
        if (this.getCaretPosition(elem) < elem.value.length) {
          return false
        }
      }
      let indexOfCurrentRow = _.findIndex(this.computedAvailableRefs, (row) => {
        return row.includes(ref)
      })
      let indexOfCurrentElement = _.findIndex(this.computedAvailableRefs[indexOfCurrentRow], (r) => {
        return r === ref
      })
      let resultRef = null

      if (indexOfCurrentElement === this.computedAvailableRefs[indexOfCurrentRow].length - 1) {
        if (indexOfCurrentRow === this.computedAvailableRefs.length - 1) {
          resultRef = this.computedAvailableRefs[0][0]
        } else {
          resultRef = this.computedAvailableRefs[indexOfCurrentRow + 1][0]
        }

        if (!this.$refs[resultRef]) {
          this.onKeyPressRight(resultRef)
          return
        }
      } else {
        resultRef = this.computedAvailableRefs[indexOfCurrentRow][indexOfCurrentElement + 1]
        if (!this.$refs[resultRef]) {
          this.onKeyPressRight(resultRef)
          return
        }
      }

      if (resultRef.includes('type-')) {
        this.$refs[resultRef][0].$el.focus()
        return
      }
      if (this.$refs[resultRef]?.$el) {
        this.$refs[resultRef].$el.focus()
      } else if (this.$refs[resultRef][0]) {
        this.$refs[resultRef][0].focus()
      } else {
        this.$refs[resultRef].focus()
      }
    },
    getCaretPosition: function (ctrl) {
      // IE < 9 Support
      if (document.selection) {
        ctrl.focus()
        var range = document.selection.createRange()
        var rangelen = range.text.length
        range.moveStart('character', -ctrl.value.length)
        var start = range.text.length - rangelen
        return start
      }
      // IE >=9 and other browsers
      else if (ctrl.selectionStart || ctrl.selectionStart == '0') {
        return ctrl.selectionEnd
      } else {
        return 0
      }
    },
    onKeyPressEnter(id) {
      this.$emit('addPart', id)
    },
    saveDraggable(orderId) {
      let sort = _.map(this.orders[orderId].items, (prt, index) => {
        return {id: prt.id, index: index}
      })
      console.log(sort,)
    },
    onFocusOrderDetails(id, field) {
      this.focusedOrderDetailes = {
        orderId: id,
        field: field,
      }
    },
    onBlurOrderDetails(order) {
      if (this.focusedOrderDetailes && Number(order.id) == Number(this.focusedOrderDetailes.orderId)) {
        let originalOrder = _.find(this.manualOrdersDataOriginal, (o) => {
          return Number(o.id) == Number(order.id)
        })
        let field = this.focusedOrderDetailes.field
        if (originalOrder && originalOrder[field] !== order[field]) {
          this.saveOrder(order)
        }
      }

      this.focusedOrderDetailes = null
    },
    onFocus (ref, id, field, prtId) {
      this.currentOrderId = id
      this.focusedElement = ref
      this.focusedElementDetailes = {
        orderId: id,
        prtId: prtId,
        field: field
      }
    },
    isInFocusElement (ref) {
      if (ref === this.focusedElement) {
        return true
      }
      return false
    },
    onBlur (part, field) {
      if (this.focusedElementDetailes) {
        let updatedOrder = _.findIndex(this.manualOrdersDataOriginal, order => {
          return Number(order.id) === Number(this.focusedElementDetailes.orderId)
        })
        if (updatedOrder !== -1) {
          let updatedPrt = _.find(this.manualOrdersDataOriginal[updatedOrder].items, prt => {
            return Number(prt.id) === Number(this.focusedElementDetailes.prtId)
          })
          let selectedF = this.focusedElementDetailes.field;
          if (updatedPrt && updatedPrt[selectedF] !== part[selectedF]) {
            this.saveOrderPart(this.focusedElementDetailes.orderId, part)
          }
        }
      }

      this.focusedElement = ''
      this.focusedElementDetailes = null
    },
  }
}
</script>

<style scoped>
.text-warning {
  color: #F59345 !important;
}
.text-success {
  color: #43B968 !important;
}
.incorrect-part-ordered-icon {
  color: red !important;
}
.gray-icon {
  color: rgb(121,121,121) !important
}
.processing-icon-orange {
  color: orange !important;
}

.bxs-truck.text-primary {
  color: #43B968 !important;
}

.orders-table-body-row{
  width: 100%;
  border-bottom: 1px solid rgba(27, 30, 56, .25);
}
.orders-table-body{
  /*border-bottom: 1px solid rgba(27, 30, 56, .25);*/
  width: 100%;
}
.orders-table-body-row-item{
  padding: .75rem;
  height: 53px;
  width: 100%;
}
.orders-table-body-row-item:nth-child(1){
  max-width: 115px;
}
.orders-table-body-row-item:nth-child(5), .orders-table-body-row-item:nth-child(6){
  max-width: 65px;
}
.orders-table-body-row-item:nth-child(10), .orders-table-body-row-item:nth-child(7),
.orders-table-body-row-item:nth-child(8){
  max-width: 90px;
}
.orders-table-body-row-item:nth-child(4){
  min-width: 190px;
}
.orders-table-header{
  background-color: #e1e2e5;
  font-size: 13px;
  font-weight: 700;
}
.orders-table-header-item:nth-child(1){
  max-width: 115px;
}
.orders-table-header-item:nth-child(4){
  min-width: 190px;
}
.orders-table-header-item:nth-child(5), .orders-table-header-item:nth-child(6){
  max-width: 65px;
}
.orders-table-header-item:nth-child(4), .orders-table-header-item:nth-child(10),
.orders-table-header-item:nth-child(7), .orders-table-header-item:nth-child(8){
  max-width: 90px;
}
.orders-table-header-item{
  width: 100%;
  padding: 10px;
}
.orders-body{
  margin: 15px;
}
.orders-summary{
  width: 20%;
}
.orders-section{
  width: 80%;
  margin-left: 35px;
}
.orders-header{
  line-height: 48px;
  font-size: 14px;
  font-weight: 700;
  background-color: rgba(27, 30, 56, 0.12);
  border-radius: 3px;
  padding: 5px 15px;
  min-height: 48px;
}
.orders-summary-info{
  margin: 15px 10px;
}
.orders-summary-info-item{
  margin-bottom: 15px;
}
.orders-summary-info-item-name{
  margin-right: 15px;
}
.orders-summary-info-item-user{
  font-weight: bold;
  word-spacing: 8px;
}
.orders-summary-using{
  margin-top: 40px;
}
.orders-section-item-header-elem_name{
  display: flex;
  align-items: center;
  margin-right: 5px;
}
.orders-section-item-header-elem_info{
  font-weight: 900;
  color: #5d78fd;
  text-shadow: 0 1px 0 #5d78fd;
}
.orders-section-item-header{
  margin: 15px 10px;
  align-items: center;
}
.orders-section-item-header-elem{
  margin-right: 15px;
}

.orders-section-body{
  padding-bottom: 20px;
  border-bottom: 4px solid #e1e2e5;
}
.orders-section-body:last-child{
  border-bottom: none;
}
.orders-table-body div{
  font-size: 11px;
  font-weight: 900;
}
.orders-table-body .bx{
  font-size: 24px;
  margin-right: 5px;
}
.orders-table-body .bx-exit,
.orders-table-body .bx-edit-alt {
  color: #2e6ba3;
}
.orders-table-body .bx-chat.bx-chat-yellow{
  color: #d59800;
}
.orders-table-body .bx-trash{
  color: red;
}
.orders-table-body .bx-chat.bx-chat-grey{
  color: #a5a3a3;
}
.orders-table-body .bx-dollar-circle.bx-dollar-circle-green{
  color: #4b8723;
}
.orders-table-body .bx-dollar-circle.bx-dollar-circle-grey{
  color: #a5a3a3;
}
.orders-summary-add-manual-order{
  margin-top: 40px;
}


.tab-header-orders.active{
  background: linear-gradient(135deg, rgb(114, 103, 231) 0%, rgb(98, 89, 239) 49.12%, rgb(78, 69, 238) 100%);
  color: #f8f8f8;
}
.tab-header-orders{
  border: 1px solid rgb(94, 121, 255);
  color: rgb(94, 121, 255);
  font-weight: bold;
}
</style>
