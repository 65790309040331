import { render, staticRenderFns } from "./vehicle-booking.vue?vue&type=template&id=05608876&scoped=true"
import script from "./vehicle-booking.vue?vue&type=script&lang=js"
export * from "./vehicle-booking.vue?vue&type=script&lang=js"
import style0 from "./vehicle-booking.vue?vue&type=style&index=0&id=05608876&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05608876",
  null
  
)

export default component.exports